@import "config";
@import "reset";

#root {
   height: 100%;
}

.wrapper {
   min-height: 100%;
   display: flex;
   flex-direction: column;
   overflow: hidden;
   > main {
      flex: 1 1 auto
   }
   > * {
      min-width: 0;
   }
}

.policy {
   line-height: 1.4;
   margin-bottom: rem(80);
   h1 {
      font-size: rem(32);
      &:not(:last-child) {
         margin-bottom: rem(10);
      }
   }
   h2 {
      margin-top: rem(40);
      font-size: rem(24);
      &:not(:last-child) {
         margin-bottom: rem(10);
      }
   }
   p {
      &:not(:last-child) {
         margin-bottom: rem(10);
      }
   }
   ul {
      padding-left: rem(25);
      &:not(:last-child) {
         margin-bottom: rem(10);
      }
      li {
         list-style: disc;
         &:not(:last-child) {
            margin-bottom: rem(10);
         }
      }
   }
}