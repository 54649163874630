@use "sass:math";
@import "src/styles/config";


.block {
  &:not(:last-child) {
    margin-bottom: rem(60);
  }
  @media (max-width: $mobile) {
    &:not(:last-child) {
      margin-bottom: rem(40);
    }
  }
}

.title {
  @include adaptiveValue("font-size", 32, 28);
  text-align: center;
  color: #11153a;
  line-height: math.div(48, 32);
  font-weight: 800;

  &:not(:last-child) {
    margin-bottom: rem(20);
  }
}

.search {
  position: relative;
}

.searchActions {
  position: relative;

  input {
    background: #ffffff;
    border: rem(3) solid rgba(82, 112, 238, 0.5);
    border-radius: rem(50);
    overflow: hidden;
    width: 100%;
    height: rem(80);
    padding: 0 rem(80) 0 rem(30);
    @include adaptiveValue("font-size", 24, 19);
    line-height: math.div(36, 24);
    overflow: hidden;
    text-overflow: ellipsis;
    transition: box-shadow 0.2s ease 0s;

    &:focus {
      box-shadow: 0 0 10px rgba(82, 113, 238, 0.5);
    }

    &::placeholder {
      color: rgba(17, 21, 58, 0.5);
    }

    @media (max-width: $mobile) {
      height: rem(64);
      padding: 0 rem(60) 0 rem(25);
    }
  }
}

.button {
  position: absolute;
  z-index: 1;
  right: rem(10);
  top: rem(10);
  width: rem(60);
  height: rem(60);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: rem(28.5);
  color: #5270ee;
  background-color: #cbd4fa;
  border: rem(2) solid transparent;
  transition: background-color 0.2s ease 0s, border-color 0.2s ease 0s, transform 0.1s ease 0s;
  will-change: transform;
  &:active {
    transform: scale(0.98);
  }

  &:focus {
    border-color: rgba(82, 113, 238, 0.5);
  }

  &.loading {
    svg{
      animation: rotate 1s infinite linear;
    }
    pointer-events: none;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
   }

  @media (any-hover: hover) {
    &:hover {
      background-color: #c7cef3;
    }
  }
  @media (max-width: $mobile) {
    width: rem(48);
    height: rem(48);
    right: rem(8);
    top: rem(8);
    font-size: rem(25);

  }
}

.results {
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.3));
  position: absolute;
  z-index: 10;
  top: calc(100% + rem(15));
  left: 0;
  width: 100%;

  background-color: #fff;
  padding: rem(25) rem(30);
  border: rem(3) solid rgba(82, 112, 238, 0.5);
  border-radius: rem(40);
  overflow: hidden;
  @include adaptiveValue("font-size", 24, 19);

  .resultsItem {
    display: flex;
    align-items: center;
    transition: color 0.1s ease 0s;
    text-transform: capitalize;

    &:not(:last-child) {
      margin-bottom: rem(20);
    }
  ;

    @media (any-hover: hover) {
      &:hover {
        color: #5270ee;
      }
    }
  }
}

