@import "mixins";
@import url("https://fonts.googleapis.com/css?family=Poppins:regular,500,600,700,800&display=swap");

$fontFamily: "Poppins";
$fontSize: 19;

$mainColor: #373a5a;

$minWidth: 320;
$maxWidth: 1920;
$maxWidthContainer: 1040;
$containerPadding: 40;

$containerWidth: $maxWidthContainer + $containerPadding;

$pc: em($containerWidth);
$tablet: em(991.98);
$mobile: em(767.98);
$mobileSmall: em(479.98);
