@use "sass:math";
@import "src/styles/config";

.banner {
  padding: 0 rem(10);
  &:not(:last-child) {
    @include adaptiveValue("margin-bottom", 80, 60);
  }
}

.bannerWrapper {
  position: relative;
  max-width: rem(1578);
  margin: 0 auto;
  @include adaptiveValue("border-radius", 40, 30);
  background: linear-gradient(94.65deg, #617ef9 0%, #3e5ddf 100%);
  overflow: hidden;
  padding: rem(25) 0;

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    background: url("../../assets/images/mainblock/waves.webp") bottom right / cover no-repeat;
  }

  @media (max-width: $tablet) {
    padding: rem(40) rem(20);
  }
  @media (max-width: $mobile) {
    &::after {
      height: 60%;
    }
  }
}

.body {
  position: relative;
  z-index: 2;
  display: grid;
  align-items: center;
  row-gap: rem(40);
  @include adaptiveValue("column-gap", 40, 20, $containerWidth, 768);
  @media (min-width: $mobile) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.content {
  position: relative;
  z-index: 2;
  h1 {
    white-space: nowrap;
    color: #fff;
    text-transform: capitalize;
    font-weight: 800;
    @include adaptiveValue("font-size", 44, 32, 0, $containerWidth, 768);
    line-height: math.div(62, 44);

    span {
      color: #ffc702;
    }

    &:not(:last-child) {
      margin-bottom: rem(20);
    }

    @media (max-width: $pc) {
      white-space: normal;
      br {
        display: none;
      }
    }
  }
}

.button {
  display: inline-flex;
  padding: rem(11) rem(20);
  justify-content: center;
  align-items: center;
  min-width: rem(240);
  background: linear-gradient(92.44deg, #ffe600 0%, #ffc120 100%);
  border-radius: rem(50);
  color: #11153a;
  font-weight: 600;
  line-height: math.div(28, 19);
  transition: box-shadow 0.2s ease 0s;
  &:focus{
    box-shadow: 0 0 10px #ffe600;
  }
  @media (any-hover: hover) {
    &:hover {
      box-shadow: 0 0 10px #ffe600;
    }
  }
}

.image {
  position: relative;
  z-index: 2;
  padding-bottom: percent(400, 578);
  @media (min-width: ($pc + 100)) {
    width: rem(578);
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
  }
}




