@use "sass:math";
@import "src/styles/config";


.modal {
   position: fixed;
   z-index: 9999999;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: rgba(0, 0, 0, 0.7);
}

.modalWrapper {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
}

.modalBody {
   position: relative;
   @media (max-width:$tablet){
      width: 90%;
      margin: 0 auto;
   }

}

.modalContent {
   position: relative;
   padding: rem(30) rem(60);
   height: rem(490);
   border-radius: 30px;
   overflow: hidden;
   background: url("../../assets/images/download-modal/bg.webp") center / cover no-repeat;
   @media (min-width: $tablet) {
      width: rem(800);
   }
   @media (max-width:$mobileSmall){
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      height: rem(600);
      @include adaptiveValue("height", 700, 600, 475, 375);
      background: url("../../assets/images/download-modal/bg-mobile.png") center / cover no-repeat;
   }
}

.modalBg {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
   }
}

.logo {
   height: rem(240);
   width: rem(200);
   margin-bottom: rem(25);
   position: relative;
   img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
   }
   @media (max-width:$mobileSmall){
      height: rem(150);
      width: rem(125);
   }
}

.closeBtn {
   position: absolute;
   transform: translate(80%, -80%);
   top: 0;
   right: 0;
   font-size: rem(25);
   color: #ffffff;
   transition: color 0.1s ease 0s;
   @media (max-width:$mobile){
      transform: translate(0, -100%);
   }
}